import { useTranslation } from 'react-i18next'
import { LinkButton } from '../button/link-button'
import Modal from '../modal/modal'
import Text from '../text/text'

export function LocationInfoModal({
	open,
	setOpen,
}: {
	open: boolean
	setOpen: (open: boolean) => void
}) {
	const { t } = useTranslation()
	return (
		<>
			{open && (
				<Modal onClose={() => setOpen(false)} title="Aktivera Platstjänster">
					<div className="flex flex-col items-center justify-center space-y-4">
						<Text>{t('location_you_have_disabled_access')}</Text>
						<div className="flex w-full justify-between space-x-2">
							<LinkButton
								to="/guide/location-reset"
								variant="primary"
								onClick={() => setOpen(false)}
							>
								{t('action_read_more')}
							</LinkButton>
						</div>
					</div>
				</Modal>
			)}
		</>
	)
}
